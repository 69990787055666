import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img, { FluidObject } from "gatsby-image"

import { P, H2, H3, Container, BackGroundTexture } from "../../util/standard"

import { SMALL_LAPTOP_BREAKPOINT, TABLET_BREAKPOINT, MOBILE_BREAKPOINT, IPAD_PRO_BREAKPOINT, icons } from "../../util/constants"
import { SanityTeam, SanityBoard } from '../../../graphql-types'


interface Props {
    profilePic?: FluidObject;
    name: string | null | undefined;
    jobTitle: string | null | undefined;
    email: string | null | undefined;
    profileBio: string | null | undefined;
    blueName: boolean;
    title: string;
}

const ProfileWrapper = styled(Container)`
    width: 100%;
    flex-wrap: wrap;
    border-bottom: 1px solid #b8b6b6;
    padding: 40px 0;

    display: inline-grid;
    grid-template-columns:100px 22% 60%;
    grid-gap: 2%;

    @media (max-width:${SMALL_LAPTOP_BREAKPOINT}px){
        grid-template-columns:100px auto ;
        grid-gap:20px;
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px){
        display:flex
    }
`

const ProfilePic = styled(Img)`
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: grey;
    margin-right:20px;
`

const DetailsWrapper = styled.div`
margin-right: 20px;
@media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
}

`

const Name = styled(P) <{ blueName?: boolean }>`
    margin: 10px auto;
    color: #00A032;
    ${({ blueName }) => blueName && `color: #004696;`}
`

const StyledP = styled(P)`
    margin: 10px 0;
`

const Bio = styled(P)`
width: 100%;

@media (max-width:${SMALL_LAPTOP_BREAKPOINT}px){
    grid-column-start:1;
    grid-column-end:3;
}
`

const TeamContainer = styled.div`
    width: 70%;
    margin: 100px auto;
    padding-bottom: 50px;

    @media (max-width: ${MOBILE_BREAKPOINT}px){
        width:80%;
        margin: 50px auto;
        padding-bottom: 0px;
    }
`

const Title = styled(H2)`
    color: black;
`

function Profile(props: Props) {

    const { profilePic, name, email, jobTitle, profileBio, blueName, title } = props

    return (
        <ProfileWrapper>
            <ProfilePic fluid={profilePic} alt={title ?? 'Team-member-picture'} />
            <DetailsWrapper>
                <Name blueName={blueName} fontWeight="bold">{name}</Name>
                <StyledP>{jobTitle}</StyledP>
                <StyledP>{email}</StyledP>
            </DetailsWrapper>
            <Bio>{profileBio}</Bio>
        </ProfileWrapper>
    )
}

function Team() {

    const { sanityAboutPage } = useStaticQuery(graphql`
     {
        sanityAboutPage {
            team {
            memberName
            memberBio
            jobTitle
            profilePhoto {
                asset {
                fluid {
                    srcWebp
                    srcSetWebp
                    srcSet
                    src
                }
                title
                }
            }
            email
            _key
            }
              board {
        profilePhoto {
          asset {
            fluid {
              srcWebp
              srcSetWebp
              srcSet
              src

            }
            title
          }
        }
        memberBio
        jobTitle
        boardMemberName
        _key
      }
    }
    }
    `)

    const { team, board } = sanityAboutPage

    return (
        <>
        <TeamContainer>
            <Title fontWeight="bold">Team</Title>
            {team.map((teamMember: SanityTeam, index: number) => {
                return (
                    <Profile key={teamMember._key}
                        profilePic={teamMember.profilePhoto?.asset?.fluid as FluidObject}
                        name={teamMember.memberName}
                        email={teamMember.email}
                        jobTitle={teamMember.jobTitle}
                        profileBio={teamMember.memberBio}
                        blueName={index % 2 === 0}
                    />
                )
            })}            
        </TeamContainer>
            <TeamContainer>
                <Title fontWeight="bold">Board</Title>
                {board.map((boardMember: SanityBoard, index: number) => {
                    return (
                        <Profile key={boardMember._key}
                            profilePic={boardMember.profilePhoto?.asset?.fluid as FluidObject}
                            name={boardMember.boardMemberName}
                            email={boardMember.email ? boardMember.email : ""}
                            jobTitle={boardMember.jobTitle}
                            profileBio={boardMember.memberBio}
                            blueName={index % 2 === 0}
                        />
                    )
                })}
            </TeamContainer>
        </>
    )
}

export default Team