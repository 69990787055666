import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../components/shared/Layout";
import Hero from "../components/shared/Hero";
import ContentBlock from "../components/shared/sub/ContentBlock";
import Partners from '../components/about/Partners';
import OurMission from '../components/about/OurMission';
import Team from '../components/about/Team';
import Board from '../components/about/Board';
import DonateBlock from "../components/shared/DonateBlock";
import { Query } from "../../graphql-types";
import Video from "../components/shared/sub/Video";
import SEO from '../components/shared/Seo';

const SpaceMaker = styled.div`
  height: 1000px;
`;

export default function About() {
  const { sanityAboutPage } = useStaticQuery(graphql`
  {
  sanityAboutPage {
    titleBlock {
      _key
      _type
      _rawContent
    } 
    titleBlockLink {
      linkText
      url
    }
    videoBlock {
      videoSourceLink
      videoThumbnail {
        asset {
          fluid {
            srcWebp
            srcSetWebp
            srcSet
            src
          }
          title
        }
      }
    }
    aboutHeroBlock {
      heroText
      heroImage {
        asset {
          fluid {
            srcWebp
            srcSetWebp
            srcSet
            src
          }
          title
        }
      }
    }
     seoBlock {
          pageDescription
          pageKeyWords
          pageTitle
          ogImage {
            asset {
              url
            }
          }
        }
  }
}
  `);

  const { titleBlock, titleBlockLink, videoBlock, aboutHeroBlock } = sanityAboutPage;
  const { contentLink, _rawContent } = titleBlock;

  return (
    <div>
      <SEO seoData={sanityAboutPage?.seoBlock} slug='about' />
      <Hero heroText={aboutHeroBlock.heroText} heroImage={aboutHeroBlock.heroImage} blockVisible />
      <ContentBlock
        blocks={_rawContent}
        links={titleBlockLink}
      />
      <Video videoSource={videoBlock.videoSourceLink} thumbnail={videoBlock.videoThumbnail} />
      <Partners />
      {/* OUR MISSION HIDDEN UNTIL CLIENT HAS SUFFICIENT CONTENT FOR IT */}
      {/* <OurMission /> */}
      <Team />
      {/* <Board /> */}
      <DonateBlock />
    </div>
  );
};
