import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, navigate } from "gatsby";

import { P, A, H2, Container } from "../../util/standard";

import {
  MOBILE_BREAKPOINT,
  IPAD_PRO_BREAKPOINT,
  IPAD_BREAKPOINT,
  TABLET_BREAKPOINT,
  LAPTOP_BREAKPOINT,
  icons,
  colors,
} from "../../util/constants";
import BackgroundTexture from "../shared/sub/BackgroundTexture";
import ArrowLink from "../shared/sub/ArrowLink";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: ${colors.blue};
`;

const PartnersWrapper = styled(Container)`
  width: 90%;
  /* max-width: 1440px; */
  margin: 50px auto;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 80px;
  padding-bottom: 80px;

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
    grid-gap: 60px;
    display: block;
  }
`;

const TextWrapper = styled.div`
  width: 55%;
  margin: 0 auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    padding: 40px;
  }
`;

const IconsWrapper = styled(Container)`
  display: grid;
  max-width: 800px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  flex-wrap: wrap;
  margin: 0 auto;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    padding: 40px;
  }
  @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
    margin: 50px auto;
  }
`;

const TextHeader = styled(H2)`
  font-size: 24px;
`;

const PartnerImg = styled.img<{ wideImg: boolean }>`
  margin: auto;
  display: block;
  width: 150px;
  min-width: 80px;
  ${({ wideImg }) => wideImg && `width: 75%; min-width: 150px;`};

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    width: 75%;
    ${({ wideImg }) => wideImg && `width: 100%;`};
  }

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 50%;
    ${({ wideImg }) => wideImg && `width: 90%;`};
  }
`;

function Partners() {
  const { sanityAboutPage } = useStaticQuery(graphql`
    {
      sanityAboutPage {
        partners {
          mainText
          header
          link {
            url
            linkText
          }
          sponsorIcons {
            _key
            asset {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
              }
              title
            }
          }
        }
      }
    }
  `);

  const { partners } = sanityAboutPage;
  const { mainText, header, link, sponsorIcons } = partners;

  return (
    <Wrapper>
      <BackgroundTexture textureVisible />
      <PartnersWrapper>
        <TextWrapper>
          <TextHeader color="white" fontFamily="bold">
            {header}
          </TextHeader>
          <P color="white" fontFamily="light">
            {mainText}
          </P>
          <ArrowLink isWhite link={link} />
        </TextWrapper>
        <IconsWrapper>
          {sponsorIcons.map(
            (
              sponsorIcon: {
                _key: string | number | null | undefined;
                asset: { fluid: { src: string | undefined } };
              },
              index
            ) => {
              return (
                <PartnerImg
                  wideImg={index === 0 ? true : false}
                  key={sponsorIcon._key}
                  src={sponsorIcon.asset.fluid.src}
                  alt={sponsorIcon.asset.title ?? "support-partner-logo"}
                />
              );
            }
          )}
        </IconsWrapper>
      </PartnersWrapper>
    </Wrapper>
  );
}

export default Partners;
