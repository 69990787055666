import React, { useState } from "react";
import styled from "styled-components";
import Img from "gatsby-image";

import {
  TABLET_BREAKPOINT,
  MOBILE_BREAKPOINT,
  icons,
  LAPTOP_BREAKPOINT,
} from "../../../util/constants";
import { Maybe, SanityImage } from "@graphql-types";

interface Props {
  videoSource: Maybe<string> | undefined;
  thumbnail?: Maybe<SanityImage> | undefined;
}

const VideoWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 90%;
  }
`;

const StyledVideo = styled.iframe`
  width: 100%;
  display: block;
  margin: 100px auto;
  height: 700px;
  max-width: 1550px;

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    height: 550px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 350px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 250px;
    margin: 50px auto;
  }
`;

const ImageWrapper = styled.div`
  width: 80%;
  position: relative;
  display: block;
  margin: 100px auto;
  height: 700px;
  max-width: 1550px;

  &:hover {
    filter: brightness(0.7);
    cursor: pointer;
  }
  transition: filter 0.2s;

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    height: 550px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 350px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 250px;
    margin: 50px auto;
  }
`;

const ThumbnailImg = styled(Img)`
  width: 100%;
  height: 100%;
  position: relative;
  filter: brightness(0.6);
  max-height: 700px;
`;

const PlayButton = styled.img`
  position: absolute;
  width: 5%;
  top: 43%;
  left: 47%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 8%;
    top: 39%;
    left: 45%;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 12%;
    top: 37%;
    left: 43%;
  }
`;

function Video(props: Props) {
  const { videoSource, thumbnail } = props;
  const [videoRendered, toggleRenderVideo] = useState(thumbnail ? false : true);
  return (
    <>
      {videoRendered && (
        <VideoWrapper>
          <StyledVideo
            src={`${videoSource}?autoplay=1`}
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </VideoWrapper>
      )}
      {!videoRendered && thumbnail && (
        <ImageWrapper onClick={() => toggleRenderVideo(true)}>
          <ThumbnailImg
            fluid={thumbnail?.asset?.fluid}
            alt={thumbnail?.asset?.title ?? "video-thumbail"}
          />
          <PlayButton src={icons.playButton} alt="play-button-icon" />
        </ImageWrapper>
      )}
    </>
  );
}

export default Video;
