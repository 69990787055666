import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

import { P, A, H1, Container } from "../../../util/standard";
import {
  TABLET_BREAKPOINT,
  IPAD_BREAKPOINT,
  MOBILE_BREAKPOINT,
  icons,
} from "../../../util/constants";
import { Link } from "../../../util/types";
import ArrowLink from "./ArrowLink";
import BlockContent from "@sanity/block-content-to-react";

interface Props {
  blocks: any;
  links?: Link;
  noMargin?: boolean;
  textAlign?: string;
}

const ContentBlockWrapper = styled(Container)<{ noMargin?: boolean }>`
  width: 70%;
  margin: 0px auto 100px auto;
  ${({ noMargin }) => noMargin && `margin: 0px;`}

  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${IPAD_BREAKPOINT}px) {
    width: 80%;
    margin: 0px auto;
  }
`;

const TextWrapper = styled(Container)<{
  noMargin?: boolean;
  textAlign?: string;
}>`
  width: 100%;
  margin-top: 100px;
  ${({ noMargin }) => noMargin && `margin: 0px;`}

  display: block;

  h2 {
    font-family: "extraBold";
    font-size: 24px;
  }

  strong {
    font-family: "bold";
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin: 80px 0px 0px 0px;
    ${({ noMargin }) => noMargin && `margin: 0px;`}
    ${({ textAlign }) => textAlign && `text-align: ${textAlign}`}
  }
`;

function ContentBlock(props: Props) {
  const { blocks, links, noMargin, textAlign } = props;

  return (
    <ContentBlockWrapper noMargin={noMargin}>
      <TextWrapper noMargin={noMargin} textAlign={textAlign}>
        <BlockContent blocks={blocks} />
        {links && <ArrowLink link={links} />}
      </TextWrapper>
    </ContentBlockWrapper>
  );
}

export default ContentBlock;
